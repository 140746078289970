var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticStyle: { "margin-top": "5px" } }, [
        _c(
          "div",
          { staticClass: "button-click-left" },
          [
            _c(
              "el-select",
              {
                staticClass: "button-left-class",
                staticStyle: { "margin-right": "5px", width: "140px" },
                attrs: {
                  clearable: "",
                  "width:140": "",
                  placeholder: "Loại đối tượng",
                },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "type", $$v)
                  },
                  expression: "dataSearch.type",
                },
              },
              _vm._l(_vm.type, function (item) {
                return _c("el-option", {
                  key: item.status,
                  attrs: { value: item.status, label: item.label },
                })
              }),
              1
            ),
            _c(
              "el-input",
              {
                staticStyle: { width: "280px" },
                attrs: {
                  placeholder: "Nhập tên hoặc số điện thoại",
                  clearable: "",
                },
                on: {
                  clear: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.nameOrPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "nameOrPhone", $$v)
                  },
                  expression: "dataSearch.nameOrPhone",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.searchHeaderMethod()
                    },
                  },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "button-click-right row-data" },
          [
            _vm.checkPermission(["cashInternal_people_update"])
              ? _c(
                  "el-button",
                  {
                    staticClass: "button-over",
                    attrs: { type: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.createDialogMethod()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v("\n        Thêm mới\n      "),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.responseList,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "index",
                  fixed: "",
                  label: "STT",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", fixed: "", label: "Tên đối tượng" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Loại đối tượng",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == "internal"
                          ? _c("span", [_vm._v("Nhà trường")])
                          : _c("span", [_vm._v("Khác")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  width: "140",
                  align: "center",
                  label: "Số điện thoại",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "birthday", width: "140", label: "Ngày sinh" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "gender",
                  label: "Giới tính",
                  width: "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "description", label: "Mô tả" },
              }),
              _vm.checkPermission(["cashInternal_people_update"])
                ? _c("el-table-column", {
                    attrs: {
                      label: "Tác vụ",
                      fixed: "right",
                      width: "140",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("\n            Sửa")]
                              ),
                              !scope.row.defaultStatus
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("Xóa")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "danger",
                                        disabled: "",
                                      },
                                    },
                                    [_vm._v("Xóa")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3309954535
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paging-click row-data" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.pageNumber,
              "page-sizes": _vm.$pageListDefaultNew,
              "page-size": _vm.maxPageItem,
              layout: _vm.$pageLayoutDefault,
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("PeopleTypeCreate", {
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateMethod()
          },
        },
      }),
      _c("UpdatePeopleType", {
        ref: "UpdatePeopleType",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }